import tracking from './ga-tracking';
function setupElement(element) {
    element.addEventListener('click', () => {
        tracking.trackInteraction(element);
    });
}
window.addEventListener('load', () => {
    document.querySelectorAll('.ga-tracking').forEach(element => setupElement(element));
});
document.addEventListener('chat-hours-loaded', () => {
    const button = document.getElementById('start-chat');
    if (button) {
        setupElement(button);
    }
});
