const TRACKING_ATTRIBUTE_PREFIX = 'data-tracking-';
const LEVEL_ATTRIBUTE = 'page-level';
function push(eventData) {
    window.dataLayer?.push(eventData);
    console.log(eventData);
}
function parseElementAttributes(element) {
    const attributes = {};
    element.getAttributeNames()
        .filter((name) => name.startsWith(TRACKING_ATTRIBUTE_PREFIX))
        .forEach((name) => {
        const dataValue = element.getAttribute(name);
        const dataName = name.replace(TRACKING_ATTRIBUTE_PREFIX, '');
        attributes[dataName] = dataValue;
    });
    return attributes;
}
function createEventData(pageLevel, mainItem, listItem) {
    if (isNaN(pageLevel) || pageLevel < 0) {
        throw new Error('Unable to parse CTA tracking element, page level was not provided correctly');
    }
    if (pageLevel === 0) {
        // Home page / Main FAQ page
        return {
            pageUrl: window.location.href,
            event: 'help_portal_CTA',
            homepage_CTA_help_portal: listItem
        };
    }
    else {
        // Sub pages
        const levelPrefix = String.fromCharCode(pageLevel + 64) + '_';
        return {
            pageUrl: window.location.href,
            event: levelPrefix + 'help_portal_CTA',
            [levelPrefix + 'help_portal_main_item']: mainItem,
            [levelPrefix + 'help_portal_list_item']: listItem
        };
    }
}
/**
 * Parses an element attributes into an appropriate event data object which can be pushed to the datalayer.
 * If the element contains the page level attribute, the event data will take the page level into consideration
 * and create an appropriate data structure for each level.
 *
 * Otherwise, the event data will be parsed directly from the elements attributes with no special transformation logic.
 * The page URL will always be present on the resulting event data object.
 *
 * @param element
 */
function createEventDataForElement(element) {
    const attributes = parseElementAttributes(element);
    if (LEVEL_ATTRIBUTE in attributes) {
        const mainItem = attributes['main-item'];
        const cta = attributes['cta'];
        const level = parseInt(attributes[LEVEL_ATTRIBUTE]);
        return createEventData(level, mainItem, cta);
    }
    else {
        attributes['pageUrl'] = window.location.href;
        return attributes;
    }
}
function trackCtaInteraction(pageLevel, mainItem, listItem) {
    push(createEventData(pageLevel, mainItem, listItem));
}
function trackInteraction(element) {
    push(createEventDataForElement(element));
}
export default {
    push: push,
    trackCtaInteraction: trackCtaInteraction,
    trackInteraction: trackInteraction
};
